import React from "react";
import { PageHeader } from "./PageHeader"
import { DataBox } from "./DataBox"
import { usePurchasePlanModel } from "../purchasePlanModel";
import { PurchasePlanPrint } from "./purchasePlanPrint";

export default () => {
    const { print } = usePurchasePlanModel();
    return (
        <>
            <PageHeader />
            <DataBox />
            {print && <PurchasePlanPrint />}
        </>
    )
}