import React, { useEffect, FC } from "react";
import { Table, Button, Input, Tooltip, Checkbox } from "antd";
import { YHModal } from "@/components/YHModal";
import { ColumnsType } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { useSaleDetailModel, ISaleDetailModel } from "./saleDetailModel";
import { RouterParamType } from "@/model/navModel";
import { YhBut } from "@/components/YhButton";
import { formatIntPoint, formatNum, formatIntPoint1, bigNumber } from "@/utils/utils";
import { components } from "@/components/YHEditTable/editTable";
import useGlobalModel from "@/model/globalModel";
import { useDetailModel } from "../../detailModel";
import YhTootip from "@/components/YhTootip";
enum OPT_TYPE {
    ADD = 1,
    REMOVE = 2
}
export const MaterialModal: FC<RouterParamType> = ({ id, mode, parentInfo }) => {
    const { warehouseList } = useGlobalModel()
    const { busBusiness, customerParams } = useDetailModel()

    const {
        materialModel: visible,
        setMaterialModel,
        searchWorkMaterialData,
        workMaterialDataSource,
        addDataMaterialSource,
        initPgCfg,
        workMaterialPgCfg,
        detailAddForm,
        detailEditForm,
        detailMaterialAddList,
        detailMaterialEditList,
        workMaterialloadData,
        resetWorkMaterialloadData,
        setSearchWorkMaterialData,
        setAddDataMaterialSource,
        setWorkMaterialDataSource,
        transformsToPoMaterialDetailList,
        loading
    } = useSaleDetailModel();
    const materialList = mode === 'add' ? detailMaterialAddList : detailMaterialEditList
    const onOk = () => {
        transformsToPoMaterialDetailList(mode)
        onCancel()
    }
    const onCancel = () => {
        setMaterialModel(false);
        setAddDataMaterialSource([]);
        setSearchWorkMaterialData({})
    };
    useEffect(() => {
        if (visible) {
            let newCustomerCode = mode === "add" ? detailAddForm.customerCode ?? customerParams.customerCode : detailEditForm.customerCode ?? customerParams.customerCode;
            let warehouseCode = warehouseList.find((item: any) => item.customerCode === newCustomerCode)?.code
            workMaterialloadData(initPgCfg, { warehouseCode })
            setSearchWorkMaterialData({ warehouseCode })
        }

    }, [detailAddForm.customerCode, detailEditForm.customerCode, visible])


    const column = (optype: number) => [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '公交编码',
            width: 120,
            dataIndex: 'materialNo',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '商品编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any,record:any) =><div style={{display:"flex",flexWrap: 'nowrap'}}> <YhTootip text={text} /><span style={{color:'red'}}>{record.ytNote}</span></div>,
        }, {
            title: '商品名称',
            width: 140,
            dataIndex: 'materialName',
            // fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '可用库存',
            width: 120,
            dataIndex: 'availableNum',
           // dataIndex:'inventoryNum'
        }, {
            title: '数量',
            width: 120,
            dataIndex: 'num',
            editable: true,
        }, {
            title: '单价',
            width: 120,
            dataIndex: 'salePrice',
            editable: true,
        }, {
            title: '采购单价',
            width: 120,
            dataIndex: 'latestPurPrice',
        }, {
            title: '总计',
            width: 120,
            dataIndex: 'amount',
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '替换编号',
            width: 120,
            dataIndex: 'replaceCode',
            // fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '单位',
            width: 120,
            dataIndex: 'unitName',
        }, {
            title: '操作',
            width: 120,
            fixed: "right",
            render: (text: any, record: any) => optype === OPT_TYPE.ADD ? (
                <YhBut
                    type="add"
                    txt="添加"
                    click={() => add(record)}
                    disabled={
                        !!addDataMaterialSource.find(item => item.id === record.id) ||
                        record.availableNum === 0 ||
                        !!materialList.find(item => item.detailsId === record.id)
                    }
                />
            ) : (
                <YhBut
                    type="delete"
                    txt="删除"
                    click={() => remove(record)}
                />
            )
        }
    ];
    const newSelectColumns = (optType: number) =>
        column(optType).map((col: any, index) => {
            return {
                ...col,
                onCell: (record: ISaleDetailModel, rowIndex: number) => ({
                    colIndex: index,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: optType === OPT_TYPE.ADD ? notHandleSave : handleSave,
                    enterRow: optType === OPT_TYPE.ADD ? enterRow : null,
                }),
            }
        })
    const add = async (record: ISaleDetailModel) => {
        setAddDataMaterialSource(origin => [...origin, {...record,inventoryNum:record.availableNum}])
    }
    const remove = async (record: ISaleDetailModel) => {
        const filterAddDataSource = addDataMaterialSource.filter(item => item.id !== record.id)
        setAddDataMaterialSource(filterAddDataSource)
    }
    const enterRow = async (record: ISaleDetailModel) => {
        let findArr = addDataMaterialSource.find(item => item.materialCode === record.materialCode);
        if (findArr) {
            let filterArr = addDataMaterialSource.filter(item => item.materialCode !== findArr?.materialCode);
            setAddDataMaterialSource(filterArr)
        } else {
            setAddDataMaterialSource(origin => [...origin, record]);
        }
    }
    const notHandleSave = (row: ISaleDetailModel) => {
        let { num, salePrice, availableNum, averagePrice } = row;
        num = +formatIntPoint(num, availableNum, "选择数量>=可用库存数量");
        salePrice = +formatIntPoint1(salePrice, averagePrice, "修改价格不应小于进货价");
        salePrice = +formatNum(salePrice)
        let newRow = {
            ...row,
            num,
            salePrice,
            //amount: +formatNum(num * salePrice)
            amount:+bigNumber.toFixed(bigNumber.times(num,salePrice),2)
        }
        const index = workMaterialDataSource.findIndex((item) => row.id === item.id);
        const item = workMaterialDataSource[index];
        workMaterialDataSource.splice(index, 1, { ...item, ...newRow });
        setWorkMaterialDataSource([...workMaterialDataSource])
    }
    const handleSave = (row: ISaleDetailModel) => {
        let { num, salePrice, availableNum, averagePrice } = row;
        num = +formatIntPoint(num, availableNum, "选择数量>=可用库存数量");
        salePrice = +formatIntPoint1(salePrice, averagePrice, "修改价格不应小于进货价");
        salePrice = +formatNum(salePrice)
        let newRow = {
            ...row,
            num,
            salePrice,
            //amount: +formatNum(num * salePrice)
            amount:+bigNumber.toFixed(bigNumber.times(num,salePrice),2)
        }
        const index = addDataMaterialSource.findIndex((item) => row.id === item.id);
        const item = addDataMaterialSource[index];
        addDataMaterialSource.splice(index, 1, { ...item, ...newRow });
        setAddDataMaterialSource([...addDataMaterialSource])
    }

    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkMaterialData.replaceCode}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkMaterialData({ ...searchWorkMaterialData, replaceCode: e.target.value }) }}
            onPressEnter={() => workMaterialloadData(initPgCfg)}
            placeholder="替换编号"
            hidden
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkMaterialData.price}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkMaterialData({ ...searchWorkMaterialData, price: e.target.value }) }}
            onPressEnter={() => workMaterialloadData(initPgCfg)}
            placeholder="单价"
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkMaterialData.materialCode}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkMaterialData({ ...searchWorkMaterialData, materialCode: e.target.value }) }}
            onPressEnter={() => workMaterialloadData(initPgCfg)}
            placeholder="商品编码"
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkMaterialData.materialName}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkMaterialData({ ...searchWorkMaterialData, materialName: e.target.value }) }}
            onPressEnter={() => workMaterialloadData(initPgCfg)}
            placeholder="商品名称"
        />
        <Button onClick={() => workMaterialloadData()} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => resetWorkMaterialloadData()} shape="round">重置</Button>
    </div>

    return <YHModal
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        title="销售记录-选择配件"
        width={1000}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选配件列表" rightChildren={rightChildren} style={{ marginBottom: "5px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id as string}
                columns={newSelectColumns(OPT_TYPE.ADD) as ColumnsType<ISaleDetailModel>}
                dataSource={workMaterialDataSource}
                loading={loading}
                pagination={{
                    ...workMaterialPgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        workMaterialloadData({ pageNum, pageSize }).then();
                    },
                    showSizeChanger: true
                }}
                components={components}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选配件列表">
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id as string}
                columns={newSelectColumns(OPT_TYPE.REMOVE) as ColumnsType<ISaleDetailModel>}
                dataSource={addDataMaterialSource}
                pagination={false}
                components={components}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}