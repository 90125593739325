import useGlobalModel from "@/model/globalModel";
import { createModel } from "hox";
import { useState } from "react";
import { homeService, ISetFildes } from "./homeService";
export interface INavOptions {
    id: string,
    label: string,
    value: string,
    icon: string,
    disabled?: boolean
}
const options: INavOptions[] = [
    { id: "1", label: '首页', value: '/', icon: "icon-shouye" },
    // { id: "3", label: '预约管理', value: '/appointment', icon: "icon-Oval" },
    { id: "4", label: '工单管理', value: '/maintenance', icon: "icon-order" },
    // { id: "5-1", label: '销售订单', value: '/sale/order', icon: "icon-xiaoshoudingdan1" },
    { id: "5-2", label: '销售单', value: '/sale/so', icon: "icon-xiaoshoudingdan1" },
    { id: "5-3", label: '销售退货', value: '/sale/ro', icon: "icon-xiaoshoudingdan1" },
    { id: "6-1", label: '派工列表', value: '/workshop/workshopQuery', icon: "icon-tubiaozhizuomoban-117" },
    // { id: "6-2", label: '历史工单', value: '/workshop/historyQuery', icon: "icon-tubiaozhizuomoban-117" },
    // { id: "7-1", label: '结算列表', value: '/statements/workshopQuery', icon: "icon-jiesuan" },
    // { id: "7-2", label: '客户结算', value: '/statements/customer', icon: "icon-jiesuan" },
    // { id: "7-3", label: '厂家结算', value: '/statements/factory', icon: "icon-jiesuan" },
    // { id: "8", label: '客户回访', value: '/followUp', icon: "icon-huifang" },
    { id: "9-1", label: '库存统计', value: '/stock/statistic', icon: "icon-tongji" },
    { id: "9-2", label: '库存盘点', value: '/stock/check', icon: "icon-kucunpandian" },
    // { id: "9-3", label: '维修出库', value: '/stock/delivery', icon: "icon--_cangku" },
    // { id: "9-4", label: '维修借件', value: '/stock/borrow', icon: "icon--_cangku" },
    { id: "9-5", label: '出库单据', value: '/stock/output', icon: "icon--_cangku" },
    { id: "9-6", label: '入库单据', value: '/stock/entry', icon: "icon--_cangku" },
    { id: "11-1", label: '采购计划', value: '/purchase/plan', icon: "icon-guanli" },
    { id: "11-2", label: '采购单', value: '/purchase/po', icon: "icon-guanli" },
    { id: "11-3", label: '采购退货单', value: '/purchase/ro', icon: "icon-guanli" },
    { id: "13-1", label: '供应商档案', value: '/base/supplier', icon: "icon-gongyingshang1" },
    { id: "13-2", label: '配件档案', value: '/base/material', icon: "icon-zihuanjie2x" },
    { id: "13-3", label: '仓库管理', value: '/base/warehouse', icon: "icon-zihuanjie2x" },
    { id: "13-4", label: '数据字典', value: '/base/dict', icon: "icon-zihuanjie2x" },
    { id: "13-5-1", label: '客户管理', value: '/base/customerFiles/customer', icon: "icon-kehu" },
    { id: "13-5-2", label: '车辆管理', value: '/base/customerFiles/vehicle', icon: "icon-cheliang2" },
    { id: "13-6", label: '维修项目', value: '/base/item', icon: "icon-zihuanjie2x" },
    { id: "13-7", label: '维修技师', value: '/base/technician', icon: "icon-zihuanjie2x" },
    { id: "14-1", label: '机构管理', value: '/organization/org', icon: "icon-jigouguanli" },
    { id: "14-2", label: '门店管理', value: '/organization/shop', icon: "icon-jigouguanli" },
    { id: "14-3", label: '经营实体', value: '/organization/entity', icon: "icon-jigouguanli" },
    // { id: "15-1", label: '客户账户', value: '/account/customerManage"', icon: "icon-zhanghu" },
    // { id: "15-2", label: '供应商账户', value: '/account/supplierManage"', icon: "icon-zhanghu" },
    { id: "16-1", label: '公交业务', value: '/busBusiness/preSale"', icon: "icon-cheliang2" }
]

export const useHomeModel = createModel(function () {
    const { user: { username } } = useGlobalModel()
    const [visible, setVisible] = useState<boolean>(false)
    const [navOptions, setNavOptions] = useState<INavOptions[]>(options)
    const [checkValues, setCheckValues] = useState<string[]>([])
    const [optionsList, setOptionsList] = useState<ISetFildes[]>([])
    const setUpdate = async () => {
        let newNavOptions = navOptions.filter(item => checkValues.indexOf(item.value) !== -1).map(item1 => {
            let { value, icon, label, id } = item1
            return {
                path: id,
                name: label,
                url: value,
                createUser: username,
                icon,
            }
        })
        let { retData } = await homeService.setUpdate({ createUser: username, details: newNavOptions })
        retData && setPage()
        return retData
    }
    const setPage = async () => {

        let { retData } = await homeService.set({ createUser: username })
        setOptionsList(retData)
        setCheckValues(retData.map(item => item.url))
    }
    return {
        visible, setVisible,
        navOptions, setNavOptions,
        checkValues, setCheckValues,
        setUpdate,
        setPage,
        optionsList,
        setOptionsList

    }
})