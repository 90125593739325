import React, { useEffect, FC, useState } from "react";
import { Table, Button, Input, Tooltip } from "antd";
import { YHModal } from "@/components/YHModal";
import { ColumnsType } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { RouterParamType } from "@/model/navModel";
import { YhBut } from "@/components/YhButton";
import { EditableCell, EditableRow } from "@/components/YHEditTable/editTable";
import { usePurchasePlanDetailsModel, IMaterialDetailModel } from "./purchasePlanDetailsModel";
import { bigNumber, formatNum } from "@/utils/utils";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { ISelectOptions } from "@/types/ScmTypes";
import { ISelectOption } from "@/components/YhFormBox/Types";

enum OPT_TYPE {
    ADD = 1,
    REMOVE = 2
}

export const AddGoodsModal: FC<RouterParamType> = ({ id, mode }) => {
    const { addModalVisible: visible,
        initPgCfg,
        workDataSource,
        workPgCfg,
        addDataSource,
        searchWorkData,
        detailAddList,
        detailDataSource,
        setWorkDataSource,
        resetWorkloadData,
        setSearchWorkData,
        setAddDataSource,
        transformsToPoDetailList,
        workloadData,
        setAddModalVisible
    } = usePurchasePlanDetailsModel();
    useEffect(() => {
        workloadData()
    }, [])
    const { warehouseList } = useGlobalModel()
    const [warehouseOptions, setWarehouseOptions] = useState<ISelectOptions[]>([])
    const [shelfOptions, setShelfOptions] = useState<ISelectOptions[]>([])
    const goodList = mode === 'add' ? detailAddList : detailDataSource
    const warehousFocus = (record: any) => {
        let newMaterialDetal = record?.materialDetal?.map((item: any) => {
            let { warehouseCode, warehouseName } = item
            return {
                label: warehouseName,
                value: warehouseCode,
            }
        })
        setWarehouseOptions(newMaterialDetal)
    }
    const shelfFocus = (record: any) => {

        let { warehouseCode } = record
        let newMaterialDetal = record?.materialDetal?.filter((filItem: any) => (
            warehouseCode === filItem.warehouseCode
        ))?.map((item: any) => {
            let { shelfCode, shelfId } = item
            return {
                label: shelfCode,
                value: shelfCode,
                code: shelfId
            }
        })
        setShelfOptions(newMaterialDetal)
    }
    const wsChanges = async (value: string, option: ISelectOption, record: any) => {

        const index = workDataSource.findIndex((item) => record.id === item.id);
        const item = workDataSource[index];
        const { num, materialDetal } = record;
        const { purchasePrice } = materialDetal.find((item: any) => value === item.warehouseCode)
        workDataSource.splice(index, 1, { ...item, 
            shelfCode: "", 
            shelfId: "", 
            num, 
            purchasePrice, 
            //amount: +formatNum(num * purchasePrice), 
            amount:+bigNumber.toFixed(bigNumber.times(num,purchasePrice),2),
    });
        setWorkDataSource([...workDataSource])
    }

    const column = (optype: number) => [
        {
            title: '配件编号',
            width: 120,
            dataIndex: 'code',
            align: "left",
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '配件名称',
            width: 120,
            fixed: "left",
            dataIndex: 'name',
            align: "left",
            render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text} {record.icon === 1 && <span style={{ background: "orange", color: "white" }}>多</span>} </p></Tooltip>,
        }, {
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        }, {
            title: '采购数量',
            width: 120,
            dataIndex: 'num',
            align: "left",
            editable: true,
        }, {
            title: '单价',
            width: 150,
            dataIndex: 'purchasePrice',
            align: "left",
            editable: true,
        }, {
            title: '采购金额',
            width: 120,
            dataIndex: 'amount',
            align: "left",
        }, {
            title: '最小起订量',
            width: 120,
            dataIndex: 'minOrderNum',
            align: "left",
        }, {
            title: '商品分类',
            width: 120,
            dataIndex: 'typeName',
            align: "left",
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseCode',
            align: "left",
            editable: true,
            editType: "select",
            onSelectChange: wsChanges,
            options: warehouseOptions,
            onSelectFocus: warehousFocus,
            render: (text: string) => transOptions(warehouseList).find(item => item.value === text)?.label ?? "请选择仓库",
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
            align: "left",
            editable: true,
            editType: "select",
            onSelectFocus: shelfFocus,
            options: shelfOptions,
        }, {
            title: '积压标记',
            width: 120,
            dataIndex: 'overstock',
            align: "left",
        }, {
            title: '操作',
            width: 120,
            fixed: "right",
            align: "center",
            render: (text: string, record: IMaterialDetailModel) => optype === OPT_TYPE.ADD ? (
                <YhBut
                    type="add"
                    txt="添加"
                    click={() => add(record)}
                    disabled={!!addDataSource.find(item => item.id === record.id) ||
                    !!goodList.find(item => item.materialCode === record.code)
                    }
                />
            ) : (
                    <YhBut
                        type="delete"
                        txt="删除"
                        click={() => remove(record)}
                    />
                )
        }
    ];
    const newSelectColumns = (optType: number) =>
        column(optType).map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IMaterialDetailModel, rowIndex: number) => ({
                    colIndex: index,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    title: col.title,
                    handleSave: optType === OPT_TYPE.ADD ? notHandleSave : handleSave,
                    enterRow: optType === OPT_TYPE.ADD ? enterRow : null,
                }),
            }
        })
    const notHandleSave = (row: IMaterialDetailModel) => {
        let { warehouseCode, shelfCode, warehouseName, shelfId, purchasePrice } = row
        let newRow = {
            ...row,
            purchasePrice: +formatNum(purchasePrice),
            // amount: +formatNum(row.num * row.purchasePrice),
            amount : +bigNumber.toFixed(bigNumber.times(row.num ,row.purchasePrice),2),
            warehouseName: warehouseOptions.length !== 0 ? warehouseOptions.find(item => warehouseCode === item.value)?.label as string : warehouseName,
            shelfId: shelfOptions.length !== 0 ? shelfOptions.find(item => shelfCode === item.value)?.code as string : shelfId,
        }
        const index = workDataSource.findIndex((item) => row.id === item.id);
        const item = workDataSource[index];
        workDataSource.splice(index, 1, { ...item, ...newRow });
        setWorkDataSource([...workDataSource])
    }
    const handleSave = (row: IMaterialDetailModel) => {
        let { warehouseCode, shelfCode, warehouseName, shelfId, purchasePrice } = row
        let newRow = {
            ...row,
            purchasePrice: +formatNum(purchasePrice),
            // amount: +formatNum(row.num * row.purchasePrice),
            amount : +bigNumber.toFixed(bigNumber.times(row.num ,row.purchasePrice),2),
            warehouseName: warehouseOptions.length !== 0 ? warehouseOptions.find(item => warehouseCode === item.value)?.label as string : warehouseName,
            shelfId: shelfOptions.length !== 0 ? shelfOptions.find(item => shelfCode === item.value)?.code as string : shelfId,
        }
        const index = addDataSource.findIndex((item) => row.id === item.id);
        const item = addDataSource[index];
        addDataSource.splice(index, 1, { ...item, ...newRow });
        setAddDataSource([...addDataSource])
    }
    const add = async (record: IMaterialDetailModel) => {
        setAddDataSource(origin => [...origin, record])
    }
    const remove = async (record: IMaterialDetailModel) => {
        const filterAddDataSource = addDataSource.filter(item => item.id != record.id)
        setAddDataSource(filterAddDataSource)
    }
    const enterRow = async (record: IMaterialDetailModel) => {
        let findArr = addDataSource.find(item => item.materialCode === record.materialCode);
        if (findArr) {
            let filterArr = addDataSource.filter(item => item.materialCode !== findArr?.materialCode);
            setAddDataSource(filterArr)
        } else {
            setAddDataSource(origin => [...origin, record]);
        }
    }
    const onOK = async () => {
        transformsToPoDetailList(mode)
        onCancel()
    }
    const onCancel = () => {
        setAddModalVisible(false);
        setAddDataSource([])

    };
    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkData.code}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkData({ ...searchWorkData, code: e.target.value }) }}
            placeholder="商品编码"
            onPressEnter={() => workloadData(initPgCfg)}
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkData.name}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkData({ ...searchWorkData, name: e.target.value }) }}
            placeholder="商品名称"
            onPressEnter={() => workloadData(initPgCfg)}
        />
        <Button onClick={() => workloadData()} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => resetWorkloadData()} shape="round">重置</Button>
    </div>
    return <YHModal
        visible={visible}
        onOk={onOK}
        onCancel={onCancel}
        title="选择配件"
        width={1000}
        bodyHeight={750}
        showFooter={true}
    >
        <YhBox title="待选配件列表" rightChildren={rightChildren} style={{ marginBottom: "15px" }}>
            <Table
                // size="small"
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id}
                columns={newSelectColumns(OPT_TYPE.ADD) as ColumnsType<IMaterialDetailModel>}
                dataSource={workDataSource}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                pagination={{
                    ...workPgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        workloadData({ pageNum, pageSize });
                    },
                    showSizeChanger: true
                }}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选配件列表">
            <Table
                rowKey={record => record.id}
                rowClassName='editable-row yh-edit-table-tr'
                columns={newSelectColumns(OPT_TYPE.REMOVE) as ColumnsType<IMaterialDetailModel>}
                dataSource={[...addDataSource]}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}