import React, { useEffect, FC } from "react";
import { Table, Button, Input, Tooltip } from "antd";
import { YHModal } from "@/components/YHModal";
import { ColumnsType } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { useFactoryDetailsModel } from "./factoryDetailsModels";
import { YhBut } from "@/components/YhButton";
import { IItemDetail } from "@/views/base/item/itemType";
import { bigNumber, formatIntPoint, formatNum } from "@/utils/utils";
import { components } from "@/components/YHEditTable/editTable";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { PlusSquareOutlined } from "@ant-design/icons";
enum OPT_TYPE {
    ADD = 1,
    REMOVE = 2
}
export const ItemModal: FC<RouterParamType> = ({ id, mode }) => {
    const { itemModel: visible,
        searchWorkItemData,
        workItemDataSource,
        addDataItemSource,
        initPgCfg,
        workItemPgCfg,
        setItemModel,
        workItemloadData,
        resetWorkItemloadData,
        setSearchWorkItemData,
        setAddDataItemSource,
        setWorkItemDataSource,
        transformsToPoItemDetailList
    } = useFactoryDetailsModel();
    const { selectedNavId, replaceNav, findNav } = useNavModel();

    const onOk = () => {
        onCancel()
        transformsToPoItemDetailList(mode)
    }
    const onCancel = () => {
        setItemModel(false);
        setAddDataItemSource([]);
        setSearchWorkItemData({})
    };
    useEffect(() => {
        workItemloadData()
    }, [])
    const column = (optype: number) => [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '项目编码',
            width: 120,
            dataIndex: 'code',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '项目名称',
            width: 120,
            dataIndex: 'name',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '工时',
            width: 120,
            dataIndex: 'workHours',
            editable: true,
        }, {
            title: '单价',
            width: 120,
            dataIndex: 'price',
            editable: true,
        }, {
            title: '工时费',
            width: 120,
            dataIndex: 'amount',
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
        }, {
            title: '操作',
            width: 120,
            fixed: "right",
            render: (text: any, record: any) => optype == OPT_TYPE.ADD ? (
                <YhBut
                    type="add"
                    txt="添加"
                    click={() => add(record)}
                    disabled={!!addDataItemSource.find(item => item.id === record.id)}
                />
            ) : (
                    <YhBut
                        type="delete"
                        txt="删除"
                        click={() => remove(record)}
                    />
                )
        }
    ];
    const newSelectColumns = (optType: number) =>
        column(optType).map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IItemDetail, rowIndex: number) => ({
                    colIndex: index,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: optType == OPT_TYPE.ADD ? notHandleSave : handleSave,
                }),
            }
        })
    const add = async (record: IItemDetail) => {
        setAddDataItemSource(origin => [...origin, record])
    }
    const remove = async (record: IItemDetail) => {
        const filterAddDataSource = addDataItemSource.filter(item => item.id != record.id)
        setAddDataItemSource(filterAddDataSource)
    }
    const notHandleSave = (row: IItemDetail) => {
        let { workHours, price } = row;
        workHours = +formatIntPoint(workHours);
        price = +formatIntPoint(price);
        let newRow = {
            ...row,
            //amount: +formatNum(workHours * price)
            amount:+bigNumber.toFixed(bigNumber.times(workHours , price),2),
        }
        const index = workItemDataSource.findIndex((item) => row.id === item.id);
        const item = workItemDataSource[index];
        workItemDataSource.splice(index, 1, { ...item, ...newRow });
        setWorkItemDataSource([...workItemDataSource])
    }
    const handleSave = (row: IItemDetail) => {
        let { workHours, price } = row;
        workHours = +formatIntPoint(workHours);
        price = +formatIntPoint(price);
        let newRow = {
            ...row,
            //amount: +formatNum(workHours * price)
            amount:+bigNumber.toFixed(bigNumber.times(workHours , price),2),
        }
        const index = addDataItemSource.findIndex((item) => row.id === item.id);
        const item = addDataItemSource[index];
        addDataItemSource.splice(index, 1, { ...item, ...newRow });
        setAddDataItemSource([...addDataItemSource])
    }
    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkItemData.code}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkItemData({ ...searchWorkItemData, code: e.target.value }) }}
            onPressEnter={() => workItemloadData(initPgCfg)}
            placeholder="项目编码"
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkItemData.name}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkItemData({ ...searchWorkItemData, name: e.target.value }) }}
            onPressEnter={() => workItemloadData(initPgCfg)}
            placeholder="项目名称"
        />
        <Button onClick={() => workItemloadData()} style={{ margin: "0px 12px 0px 15px" }} shape="round">检索</Button>
        <Button onClick={() => resetWorkItemloadData()} shape="round">重置</Button>
    </div>
    const goItem = ()=>{
        replaceNav(
            selectedNavId,
            "13-6-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id
                }
            },
            true)
    }
    const title = <>
        <span>待选项目列表</span>
        <PlusSquareOutlined onClick={goItem} style={{ marginLeft: "10px", cursor: "pointer", color: "#6C72F3" }}/>
    </>
    return <YHModal
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        title="厂家结算-选择项目"
        width={1000}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title={title} rightChildren={rightChildren} style={{ marginBottom: "5px" }}>
            <Table
                rowKey={record => record.id as string}
                rowClassName='editable-row yh-edit-table-tr'
                columns={newSelectColumns(OPT_TYPE.ADD) as ColumnsType<IItemDetail>}
                dataSource={workItemDataSource}
                pagination={{
                    ...workItemPgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        workItemloadData({ pageNum, pageSize }).then();
                    },
                    showSizeChanger: true
                }}
                components={components}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选项目列表">
            <Table
                rowKey={record => record.id as string}
                rowClassName='editable-row yh-edit-table-tr'
                columns={newSelectColumns(OPT_TYPE.REMOVE) as ColumnsType<IItemDetail>}
                dataSource={addDataItemSource}
                pagination={false}
                components={components}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}