import React from "react"
import { YhBox } from "@/components/YhBox";
import { Button, Menu, Dropdown, Upload, message, Input } from "antd";
import { PreSaleDataTable } from "./DataTable"
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { usePreSaleModel } from "./presaleModel";
import { formatNum } from "@/utils/utils";
import { globalPrompt } from "@/components/message";
import useNavModel from "@/model/navModel";
import { DownOutlined } from "@ant-design/icons";
import { usePreSaleDetailModel } from "../view/detail/presale/detail/presaleDetailModel";
import { PreSaleSearchBox } from "./SearchBox"
import { importPropsConfig } from "@/utils/importFile";
import { SalePrint } from "./SalePrint"
import useGlobalModel from "@/model/globalModel";
export const PreSaleAll = () => {
    const {
        selectedRowKeys,
        rowsDescTotal,
        descTotal,
        selectedRow,
        descRows,
        invalidateData,
        exportSaleSo,
        detailsExport,
        listDownloadTpl,
        loadData,
        print,
        setPrint,
        preSaleBatchWarehousing
    } = usePreSaleModel();
    const { resetEditCatch } = usePreSaleDetailModel()
    const { selectedNavId, replaceNav, addNav, findNav, selectNav, switchNavState } = useNavModel();
    const { user } = useGlobalModel()

    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "presale-order.stock-io") {
            globalPrompt("message", { text: "该单据已出库，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "presale-order.transfer") {
            globalPrompt("message", { text: "该单据已转接，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "presale-order.change") {
            globalPrompt("message", { text: "该单据已作废，无法进行编辑", type: "warning" });
            return;
        }
        let saleOrderEditKey = "16-2-2";
        let isFindNav = findNav(saleOrderEditKey);
        if (!isFindNav) {
            addNav(saleOrderEditKey, { id: selectedRow.orderCode as string, mode: "edit", parentInfo: {source:"List", selectedNavId } });
        } else {
            globalPrompt("modal", {
                title: "预售记录跳转详情",
                type: "warning",
                text: "您还有预售记录详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    replaceNav(
                        selectedNavId,
                        saleOrderEditKey,
                        { id: selectedRow.orderCode as string, mode: "look", parentInfo: {source:"List", selectedNavId } },
                        true
                    );
                    resetEditCatch()
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "16-2-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.orderCode as string, mode: "look", parentInfo: { selectedNavId } },
            true
        );
    }
    const onInvalidate = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let cancelReason: string = ""
        globalPrompt("modal", {
            title: "预售单作废",
            type: "error",
            text: <><span>请输入作废原因</span>
                <Input onChange={(e) => { cancelReason = e.target.value }} /></>,
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id,
                    orderCode: selectedRow.orderCode as string,
                    cancelReason
                }
                invalidateData(obj)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onTranSale = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "presale-order.transfer") {
            globalPrompt("message", { text: "该单据已转接，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "presale-order.change") {
            globalPrompt("message", { text: "该单据已作废，无法进行编辑", type: "warning" });
            return;
        }
        let supplierEditKey = "16-2-2";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            {
                id: selectedRow.orderCode as string, mode: "edit", parentInfo: {
                    TranSale: "TranSale",selectedNavId
                }
            },
            true
        );
    }
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => exportSaleSo()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => detailsExport()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    const onWhConfirm =async () => {
        let params = descRows.map((item)=>({id:item.id as string,orderCode:item.orderCode as string, storekeeper: user.username}))
        let res =await preSaleBatchWarehousing({details:params})
        if(res){
            message.warning(res.msg)
            loadData()
        }
    }
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }
    const onAdd = ()=>{
        //这里加parentInfo参数是为了与详情信息新增跳转做区分 从此处跳转标识为从列表List跳转
        addNav('16-2-1', { id: "", mode: "add",parentInfo:{source:"List",selectedNavId} })
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onAdd}
        >
            新增
        </Button>    
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onEdit}
        >
            编辑
        </Button>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onWarehousing}
        >
            库房确认
        </Button> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onTranSale}
        >
            转销售单
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onLook}
        >
            查看
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onInvalidate}
        >
            作废
        </Button>
        <Dropdown overlay={exportMenu}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
            >导出<DownOutlined /></Button>
        </Dropdown>
        {/* <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/presaleorder/list/import/${user.username}`, loadData)}>
            <Button className="mr-5" type="link" shape="round">导入</Button>
        </Upload> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onWhConfirm}
        >
            批量入库
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onPrint}
        >
            打印
        </Button>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => listDownloadTpl().then()}
        >
            下载模板
        </Button> */}
    </>
    const desc: IDescription[] = [
        {
            label: "预售数量",
            value: selectedRowKeys.length === 0 ? descTotal?.presaleNum??0 : (rowsDescTotal?.presaleNum??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "预售金额",
            value: selectedRowKeys.length === 0 ? descTotal?.presaleAmount??0 : formatNum(rowsDescTotal?.presaleAmount)??0,
            color: DescriptionColorEnum.red
        },
        // {
        //     label: "预售成本",
        //     value: selectedRowKeys.length === 0 ? descTotal?.presaleTotalCost??0 : formatNum(rowsDescTotal?.presaleTotalCost??0),
        //     color: DescriptionColorEnum.red
        // },
    ]
    return <>
        <PreSaleSearchBox />
        <YhBox title="预售列表" action={action} descriptions={desc}>
            <PreSaleDataTable />
        </YhBox>
        {print && <SalePrint/>}
    </>
}