import { globalPrompt } from "@/components/message";
import useGlobalModel from "@/model/globalModel";
import { ISearchPage } from "@/types/ScmTypes";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { convertRes2Blob } from "@/utils/exportFile";
import { bigNumber, formatNum, transPgToPagination } from "@/utils/utils";
import { IVRMshelfDetail } from "@/views/base/warehouse/warehouseTypes";
import { IStockStatisticDetail } from "@/views/stock/statistic/stockStatisticService";
import { useLockFn } from "ahooks";
import { message } from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import { createModel } from "hox";
import { Key, useEffect, useState } from "react";
import { IMaterialDetail, IMaterialSearchFields, materialService } from "../../../../base/material/materialService";
import { purchasePlanService } from "../../purchasePlanService";
import { IDetailList, IPurchasePlanFields } from "../../purchasePlanType";
export type IVrmPlanDescType = {
    numAll: number,
    itemNumAll: number,
    totalAll: number,
    [key: string]: any
}
//这里为了继承一个弹框列表的自定义属性值
export interface IMaterialDetailModel extends IMaterialDetail {
    num: number,
    amount: number,
    arrivedNum?: number,    // 0,
    arrivedTotal?: number,    // 0,
    unarrivedNum?: number,    // 0,
    planNum?: number
    minNum?: number,
}
export const usePurchasePlanDetailsModel = createModel(function () {
    const { user, shopAndOrg } = useGlobalModel()
    const { shopId, shopName, shopCode } = shopAndOrg
    const [addModalVisible, setAddModalVisible] = useState<boolean>(false)  //新增商品弹框
    const initDesc = {
        numAll: 0,  //数量
        itemNumAll: 0, //项数
        totalAll: 0 //总计
    }
    const initForm = { shopId, shopName, shopCode }
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initSearchParam: IMaterialSearchFields = {};
    //新增数据
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState<IPurchasePlanFields>(initForm)
    //新增详情列表
    const [detailAddList, setDetailAddList] = useState<IDetailList[]>([])
    //新增详情列表选中行
    const [detaiAddDataRowKeys, setDetaiAddDataRowKeys] = useState<Key[]>([]);
    const [detaiAddDataRow, setDetaiAddDataRow] = useState<IDetailList[]>([]);
    const [addDesc, setAddDesc] = useState<IVrmPlanDescType>(initDesc)

    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<IPurchasePlanFields>({})
    //查看详情列表
    const [detailLookList, setDetailLookList] = useState<IDetailList[]>([])
    const [lookDesc, setLookDesc] = useState<IVrmPlanDescType>(initDesc)

    //详情数据
    //详情列表参数
    const [detailDataSource, setDetailDataSource] = useState<IDetailList[]>([]);
    //详情表格选中行
    const [detailDataRowKeys, setDetailDataRowKeys] = useState<Key[]>([]);
    const [detailDataRow, setDetailDataRow] = useState<IDetailList[]>([]);
    const [detailEditForm, setDetailEditForm] = useState<IPurchasePlanFields>({})
    const [editDesc, setEditDesc] = useState<IVrmPlanDescType>(initDesc)

    //配件列表配置
    const [workDataSource, setWorkDataSource] = useState<IMaterialDetailModel[]>([])
    const [searchWorkData, setSearchWorkData] = useState<IMaterialSearchFields>(initSearchParam)
    const [workPgCfg, setWorkPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //已选配件列表
    const [addDataSource, setAddDataSource] = useState<IMaterialDetailModel[]>([]);

    //表单改变或表格数据发生改变时，控制功能按钮的启用禁用
    const [topButsControl, setTopButsControl] = useState<boolean>(false)
    //实时详情列表货位数据
    const [shelfAllList, setShelfAllList] = useState<IVRMshelfDetail[]>([])
    //设置底部总数展示（查看|编辑|查看）
    useEffect(() => {
        let newDesc: IVrmPlanDescType = initDesc;
        detailDataSource && detailDataSource.forEach(item => {
            newDesc.numAll = bigNumber.add(newDesc.numAll, Number(item.planNum));
            newDesc.totalAll = bigNumber.add(newDesc.totalAll, Number(item.price) * Number(item.planNum))

            // newDesc.numAll += Number(item.planNum);
            // newDesc.totalAll += Number(item.price) * Number(item.planNum);
        });
        newDesc.itemNumAll = detailDataSource && detailDataSource.length
        setEditDesc(newDesc);
    }, [detailDataSource])

    useEffect(() => {
        let newDesc: IVrmPlanDescType = initDesc;
        detailAddList && detailAddList.forEach(item => {
            newDesc.numAll = bigNumber.add(newDesc.numAll, Number(item.planNum));
            newDesc.totalAll = bigNumber.add(newDesc.totalAll, bigNumber.times(Number(item.planNum),Number(item.price)))

            // newDesc.numAll += Number(item.planNum);
            // newDesc.totalAll += Number(item.planNum) * Number(item.price);
        });
        newDesc.itemNumAll = detailAddList && detailAddList.length
        setAddDesc(newDesc);
    }, [detailAddList])

    useEffect(() => {
        let newDesc: IVrmPlanDescType = initDesc;
        detailLookList && detailLookList.forEach(item => {
            newDesc.numAll = bigNumber.add(newDesc.numAll, Number(item.planNum));
            newDesc.totalAll = bigNumber.add(newDesc.totalAll, bigNumber.times( Number(item.planNum),Number(item.price)))
            
            // newDesc.numAll += Number(item.planNum);
            // newDesc.totalAll += Number(item.planNum) * Number(item.price);
        });
        newDesc.itemNumAll = detailLookList && detailLookList.length
        setLookDesc(newDesc);
    }, [detailLookList])
    const typeForm = {
        add: { ...detailAddForm, shopId, shopCode, shopName },
        edit: detailEditForm,
        look: detailLookForm
    }
    //新增表单缓存
    const updateAddFormVal = (val: IPurchasePlanFields) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updatEditFormVal = (val: IPurchasePlanFields) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }
    //获取一条编辑或查看数据
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await purchasePlanService.one(id);
        // return console.log(retData)
        let { details,md5, fileName, ...from } = retData
        let fileList: any = []
        if(md5 && fileName){
            fileList = [
                {
                    uid: md5,
                    status: 'done',
                    name: fileName,
                    response: {
                        retData: md5,
                    },
                },
            ]
        }
        from.fileList = fileList
        if (retData && pattern === "edit") {
            setDetailDataSource(details as IDetailList[] ?? [])
            setDetailEditForm(from)
            setTopButsControl(false)
        } else if (retData && pattern === "look") {
            setDetailLookForm(from);
            setDetailLookList(details as IDetailList[] ?? [])
        }
    }
    //关闭弹框
    const resetWorkloadData = async () => {
        setSearchWorkData({});
        workloadData(initPgCfg, {});
    }
    //加载配件数据
    const workloadData = async (page?: ISearchPage, searchValue: IMaterialSearchFields = searchWorkData) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            // page: {
            pageSize: page?.pageSize ?? workPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            // }
        }
        let { retData } = await materialService.page(searchParams);
        const { records, ...pg } = retData;
        const newRecords = records.map(item => {
            let { latestPurPrice, purchasePrice, materialDetal } = item
            let newPurchasePrice = materialDetal && materialDetal?.[0]?.purchasePrice ? materialDetal?.[0]?.purchasePrice as number :purchasePrice?purchasePrice: 0
            return {
                ...item,
                num: 1,
                purchasePrice: newPurchasePrice,
                amount: newPurchasePrice * 1,
                icon: materialDetal && materialDetal?.length !== 0 && materialDetal?.length > 1 ? 1 : 0,
                warehouseCode: materialDetal?.[0]?.warehouseCode as string,
                warehouseName: materialDetal?.[0]?.warehouseName as string,
                shelfCode: materialDetal?.[0]?.shelfCode as string,
                shelfId: materialDetal?.[0]?.shelfId as string,
            }
        })
        setWorkPgCfg(transPgToPagination(pg));
        setWorkDataSource(newRecords);
    }
    //筛选模块详情 详情和新增的列表已有数据的方法
    const modelScreenMethod = async (modelList: IMaterialDetailModel[], detailList: IDetailList[]) => {
        console.log(modelList, detailList)
        const repetition: string[] = [];
        const newDetailData: IMaterialDetailModel[] = []
        modelList.forEach(Item => {
            let isSelect = detailList && detailList.find(item => item.materialCode === Item.code);
            if (isSelect === undefined) {
                newDetailData.push({
                    ...Item,
                    arrivedTotal: 0,
                    unarrivedNum: 0,
                    planNum: Item.num,
                    num: 0,
                    minNum: Item.minOrderNum,
                    materialCode: Item.code ?? Item.materialCode,
                    materialName: Item.name ?? Item.materialName,
                    price: Item.purchasePrice ?? Item.price,
                    id: "",
                });
            } else {
                repetition.push(Item.name);
            }
        })
        return { newDetailData, repetition }
    }
    //添加到详情列表
    const transformsToPoDetailList = async (mode: string) => {
        //添加列表
        if (mode === "add") {
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailAddList)
            let newData: any = []
            if (typeForm[mode].purchaseTypeCode == 'purchase-type.zf') {
                newData = newDetailData.map((item) => {
                    return {
                        ...item,
                        preTaxAmount: item.price,
                        taxAmount: +bigNumber.toFixed(bigNumber.times(bigNumber.times((item.price as number), 0.13), (item.planNum as number)), 2),
                        price: +bigNumber.toFixed(bigNumber.times((item.price as number), 1.13), 2),
                        amount: +bigNumber.toFixed(bigNumber.times(bigNumber.times((item.price as number), 1.13), (item.planNum as number)), 2),
                    }
                })
            } else { newData = newDetailData }
            setDetailAddList([...detailAddList, ...newData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode === "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailDataSource)
            let newData: any = []
            if (typeForm[mode].purchaseTypeCode == 'purchase-type.zf') {
                newData = newDetailData.map((item) => {
                    return {
                        ...item,
                        preTaxAmount: item.price,
                        taxAmount: +bigNumber.toFixed(bigNumber.times(bigNumber.times((item.price as number), 0.13), (item.planNum as number)), 2),
                        price: +bigNumber.toFixed(bigNumber.times((item.price as number), 1.13), 2),
                        amount: +bigNumber.toFixed(bigNumber.times(bigNumber.times((item.price as number), 1.13), (item.planNum as number)), 2),
                    }
                })
            } else { newData = newDetailData }
            setDetailDataSource([...detailDataSource, ...newData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        }
    }
    //删除详情列表数据
    const detailDetailList = async (mode: string) => {
        if (mode === "add") {
            const filterDetailList = detailAddList.filter(el => !!!detaiAddDataRowKeys.find(ele => ele === el.materialCode))
            setDetailAddList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetaiAddDataRowKeys([]);
            setDetaiAddDataRow([]);
        } else if (mode === "edit") {
            const filterDetailList = detailDataSource.filter(el => !!!detailDataRowKeys.find(ele => ele === el.materialCode))
            console.log(filterDetailList)
            setDetailDataSource(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailDataRowKeys([]);
            setDetailDataRow([]);
        }
    }
    //表单计算校验 (计算主单信息与配件每一项)
    const formComputeRule = (mode: string): boolean => {
        let goods = mode === "edit" ? detailDataSource : detailAddList;
        let { totalAll } = mode === "edit" ? editDesc : addDesc;

        totalAll = +formatNum(totalAll);//采购总金额

        let goodsTotal: number = 0;
        goods.forEach(item => {
            let { amount } = item as { amount: number }
            // goodsTotal +=amount
            goodsTotal = bigNumber.add(goodsTotal,amount)
        })
        if (+formatNum(goodsTotal) !== totalAll) {
            message.warning(`详情采购总金额${+formatNum(goodsTotal)}与表单采购总金额不符`);
            return false;
        }
        return true
    }
    //新增采购单
    const insertDetail = useLockFn(async (params: IPurchasePlanFields) => {
        let newParams = {
            ...params,
            details: detailAddList,
            createUser: user.username,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await purchasePlanService.insert(newParams);
        return retData;
    })
    //编辑采购单
    const editDetail = useLockFn(async (params: IPurchasePlanFields) => {
        let newParams = {
            ...params,
            details: detailDataSource,
            updateUser: user.username,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await purchasePlanService.update(newParams);
        return retData;
    })
    //关闭页签清不同状态缓存
    const resetAddCatch = async (type: string) => {
        setDetailAddList([]);
        setDetailAddForm({})
    }
    const resetEditCatch = async (type: string) => {
        setDetailEditForm({});
        setDetailDataSource([]);
    }
    const downloadTpl = async () => {
        let result = await purchasePlanService.downloadTpl();
        convertRes2Blob(result)
    }
    const Transfer = useLockFn(async () => {
        let { retData } = await purchasePlanService.transfer(detailEditForm.planCode as string);
        return retData
    })
    const [historyVisible, setHistoryVisible] = useState<boolean>(false)
    const [historyRow, setHistoryRow] = useState<IStockStatisticDetail>({})
    const [supplierHistoryVisible, setSupplierHistoryVisible] = useState<boolean>(false) //消费记录展示
    const [supplierHistoryModel, setSupplierHistoryModel] = useState<{ supplierName: string }>({ supplierName: "" }) //消费记录参数
    return {
        addModalVisible,
        initPgCfg,
        detailAddForm,
        detailAddList,
        detaiAddDataRow,
        addDesc,
        detailLookForm,
        detailLookList,
        lookDesc,
        detailDataSource,
        detailDataRowKeys,
        detailDataRow,
        detailEditForm,
        editDesc,
        workDataSource,
        searchWorkData,
        workPgCfg,
        addDataSource,
        shelfAllList,
        detaiAddDataRowKeys,
        setAddModalVisible,
        setDetailAddForm,
        setDetailAddList,
        setDetaiAddDataRow,
        setAddDesc,
        setDetailLookForm,
        setDetailLookList,
        setLookDesc,
        setDetailDataSource,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailEditForm,
        setEditDesc,
        setWorkDataSource,
        setSearchWorkData,
        setWorkPgCfg,
        setAddDataSource,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetWorkloadData,
        workloadData,
        transformsToPoDetailList,
        detailDetailList,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        setShelfAllList,
        setDetaiAddDataRowKeys,
        downloadTpl,
        modelScreenMethod,
        Transfer,
        initForm,
        topButsControl, setTopButsControl,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow,
        supplierHistoryVisible, setSupplierHistoryVisible,
        supplierHistoryModel, setSupplierHistoryModel,
        formComputeRule
    }
})