import { YhAuth } from "@/components/auth/YhAuth";
import { globalPrompt } from "@/components/message";
import { YhFormBox } from "@/components/YhFormBox";
import { FormItemTypeEnum, ISelectOption, IYhFormBox, IYhFormItemsBox } from "@/components/YhFormBox/Types";
import FontIcon from "@/components/YhIcon";
import useGlobalModel, { getFindItem, transOptions } from "@/model/globalModel";
import useNavModel from "@/model/navModel";
import { DetailType } from "@/types/ScmEnums";
import { ITopType } from "@/types/ScmTypes";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { getBaseImgLookUrl, getBaseImgUploadUrl, getBaseUploadData } from "@/utils/constants";
import { fileDownload } from "@/utils/exportFile";
import { useDictModel } from "@/views/base/dict/dictModel";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, message, Modal, Tooltip, UploadFile } from "antd";
import Upload, { UploadChangeParam } from "antd/lib/upload";
import { debounce } from "lodash";
import { FC, useEffect, useState } from "react";
import { usePurchasePlanModel } from "../../purchasePlanModel";
import { usePurchasePlanDetailsModel } from "./purchasePlanDetailsModel";

export const FormBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const {
        detailEditForm,
        detailAddForm,
        detailLookForm,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetAddCatch,
        resetEditCatch,
        insertDetail,
        editDetail,
        setDetailAddForm,
        setDetailAddList,
        setTopButsControl,
        setSupplierHistoryModel,
        setSupplierHistoryVisible,
        formComputeRule,
        setDetailLookList,
        setDetailDataSource,
    } = usePurchasePlanDetailsModel();
    const {
        setSelectedRowKeys,
        setSelectedRow,
        setDescRows,
    } = usePurchasePlanModel()
    const typeList = {
        "add": setDetailAddList,
        "edit": setDetailDataSource,
        "look": setDetailLookList,
    }
    const { beShopDs, supplieAllList, shopAndOrg: { shopId, shopCode, shopName } } = useGlobalModel();
    const [more, setMore] = useState<boolean>(true);  //控制更多按钮
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const { getSelectedDictOne } = useDictModel();

    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
        mode === DetailType.look && getDetailEditOne(id, mode)
    }, [id, mode])
    useEffect(() => {
        return () => {
            !findNav("11-1-1") && resetAddCatch(mode);
            !findNav("11-1-2") && resetEditCatch(mode);
        }
    }, [selectedNavId])

    const goSupplier = () => {
        replaceNav(
            selectedNavId,
            "13-1-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id
                }
            },
            true)
    }
    const save = () => {
        //if (!formComputeRule(mode)) return;

        IFormRef.current?.validateFields().then(async res => {
            let { fileList = [], ...params } = res
            if (fileList.length > 0) {
                const file = fileList[0]
                if (!file?.uid) {
                    console.log(file)
                    return message.error('文件信息有误，请重新上传文件')
                }
                params.md5 = file?.uid
                params.fileName = file?.name
            } else {
                params.md5 = ''
                params.fileName = ''
            }
            if (mode === "add") {
                const resData = await insertDetail(params)
                if (resData) {
                    globalPrompt("message", { text: "新增成功", type: "success" });
                    resetAddCatch(mode);
                    goBack();
                    setSelectedRowKeys([resData.id as string])
                    setDescRows([resData])
                    setSelectedRow(resData)
                }
            } else if (mode === "edit") {
                let { id, planCode } = detailEditForm
                const resData = await editDetail({ ...params, id, planCode })
                if (resData) {
                    globalPrompt("message", { text: "编辑成功", type: "success" });
                    getDetailEditOne(planCode as string, mode)
                }
            }
        })
    }
        const onPreview = (file: UploadFile<any>) => {
        const { uid, name } = file
        const type = uid.split('.')[1]
        if (['jpg', 'png', 'jpeg', 'gif'].includes(type)) {
            Modal.info({
                title: '图片查看',
                content: <img style={{ width: '100%' }} src={getBaseImgLookUrl(uid)} />,
                okText: '确定',
                cancelText: '取消',
                centered: true,
                width: 800,
            })
        } else {
            // window.open(getBaseImgLookUrl(uid))
            fileDownload(getBaseImgLookUrl(uid), name)
        }
    }
        const normFile = (e: UploadChangeParam) => {
        if (e?.file.status === 'done') {
            if (e?.file?.response?.retCode == 0) {
                const data = {
                    name: e.file.name,
                    size: e.file.size,
                    type: e.file.type,
                    uid: e?.file?.response?.retData,
                }
                e.fileList = [data]
            } else {
                message.error('文件上传失败，请重新上传!')
                e.fileList?.pop()
            }
        }
        if (e.file.status === 'error') {
            message.error('文件上传失败，请重新上传!')
            e.fileList?.pop()
        }
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }
    const goBack = () => {
        replaceNav(selectedNavId, "11-1")
    }
    const onRefresh = async () => {
        if (mode === 'add') {
            IFormRef.current?.resetFields()
            setDetailAddForm({})
            setDetailAddList([])

        } else if (mode === 'edit') {
            getDetailEditOne(id, mode)
        }
        message.success("刷新成功")
    }
    const rightChildren = <>
        {mode !== "look" &&
            <YhAuth resCode={ResourceAuthEnum.P_PL_ED_SAVE}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={debounce(() => { save() }, 900)}

                >保存</Button>
            </YhAuth>
        }
        {mode !== "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onRefresh}
        >刷新</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>;
    //采购实体
    const benttityChange = (value: string, option: ISelectOption) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value });
    }
    const materialTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ materialTypeName: option.label, materialTypeCode: option.value });
    }
    const purchaseTypeChange = (value: string, option: ISelectOption) => {
        if (option.value == 'purchase-type.zf') {
            option && onChange({ purchaseTypeName: option.label, purchaseTypeCode: option.value,coefficient: '13%'});
            typeList[mode]([])
        } else {
            option && onChange({ purchaseTypeName: option.label, purchaseTypeCode: option.value,coefficient: ''});
            typeList[mode]([])
        }
    }
    const deliveryChange = (value: string, option: ISelectOption) => {
        option && onChange({ deliveryMethodName: option.label, deliveryMethod: option.value });
    }
    const typeForm = {
        add: { ...detailAddForm, shopId, shopCode, shopName },
        edit: detailEditForm,
        look: detailLookForm
    }
    const onConsumption = () => {
        let { supplierCode, supplierName } = typeForm[mode]
        if (!supplierCode) {
            return message.warning("请先选择供应商")
        }
        setSupplierHistoryVisible(true)
        setSupplierHistoryModel({ supplierName: supplierName as string })
    }
    const title = <>
        <span>基础信息</span>
        <Tooltip placement="topLeft" title="采购历史">
            <FontIcon type="icon-xiaofeijilu" style={{ marginLeft: "10px", cursor: "pointer", color: "#6C72F3" }} onClick={onConsumption}
            />
        </Tooltip>
    </>
    const baseFormItem: IYhFormItemsBox = {
        title: title,
        rightChildren,
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "采购计划号",
                fieldName: "planCode",
                disable: true,
                placeholder: "自动生成"
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goSupplier}>供应商名称</span>,
                fieldName: "supplierCode",
                allowClear: true,
                showSearch: true,
                options: transOptions(supplieAllList),
                rules: [{ required: true, message: "请选择供应商" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
                rules: [{ required: true, message: "请输入联系人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入联系电话" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "厂家订单号",
                fieldName: "orderCode",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商类型",
                fieldName: "supplierTypeName",
                disable: true,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "商品类型",
                fieldName: "materialTypeCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE),
                onSelectChange: materialTypeChange,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "运输方式",
                fieldName: "deliveryMethod",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.LOGISTIC_MODE),
                onSelectChange: deliveryChange,

            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "采购类型",
                fieldName: "purchaseTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.PURCHASE_TEPE),
                onSelectChange: purchaseTypeChange,
                rules: [{ required: true, message: "请选择采购类型" }]
            },
            ...(() => {
                if (typeForm[mode].purchaseTypeCode == 'purchase-type.zf') {
                    return [
                    {
                        type: FormItemTypeEnum.Input,
                        fieldLabel: "采购系数",
                        fieldName: "coefficient",
                        disable: true,
                    },
                ]
                } else {
                    return []
                }
            })(),{
                type: FormItemTypeEnum.Select,
                fieldLabel: "是否直发",
                fieldName: "isDirect",
                allowClear: true,
                showSearch: true,
                options: [{ label: "是", value: 1 }, { label: "否", value: 0 }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "采购实体",
                options: beShopDs,
                allowClear: true,
                showSearch: true,
                fieldName: "bentityCode",
                onSelectChange: benttityChange,
                rules: [{ required: true, message: "请选择采购实体" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                span: 5,
                labelCol: { offset: 2 },
                disable: true,
            }, {
                type: FormItemTypeEnum.Button,
                fieldLabel: <a className="color-blue text-line" onClick={() => setMore(!more)}>{more ? '更多>>' : "隐藏"}</a>,
                fieldName: "none",
                span: 1,
                labelCol: { offset: 5 },
            },
            {
                type: FormItemTypeEnum.Other,
                fieldLabel: '附件',
                fieldName: 'md5',
                colClassName: 'ant-col-h-auto',
                span: 18,
                formItem: (
                    <Form.Item name="fileList" labelCol={{ span: 2 }} label="附件" valuePropName="fileList" getValueFromEvent={normFile} >
                        <Upload
                            disabled={mode === 'look'}
                            className="upload-box"
                            onPreview={onPreview}
                            name="file"
                            action={getBaseImgUploadUrl()}
                            data={getBaseUploadData()}
                            listType="picture"
                            beforeUpload={(file:any) => {
                                const isLt20M = file.size / 1024 / 1024 < 20
                                if (!isLt20M) {
                                    message.error('上传到文件不能超过20M')
                                    file.status='done'
                                }
                                return isLt20M
                            }}
                            iconRender={(file) => {
                                return (
                                    <FontIcon
                                        onClick={() => {
                                            onPreview(file)
                                        }}
                                        style={{ fontSize: '24px' }}
                                        type="icon-tupian"
                                    />
                                )
                            }}
                        >
                            {mode !== 'look' && (
                                <>
                                    <Button icon={<UploadOutlined />}>选择文件上传</Button>
                                    <span style={{ lineHeight: '25px', color: 'green', fontSize: '13px', marginLeft: '10px' }}>
                                        提醒：上传单个文件不能超过20M,当前仅.jpg", ".png", ".jpeg", "svg", ".gif", ".pdf", ".doc等任何类型文件
                                    </span>
                                </>
                            )}
                        </Upload>
                    </Form.Item>
                ),
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "直发地址",
                fieldName: "detailedAddress",
                span: 12,
                labelCol: { offset: 1 },
                hidden: more
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "收货单位",
                fieldName: "receivingUnit",
                hidden: more
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                hidden: more
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单人",
                fieldName: "createUser",
                disable: true,
                hidden: more
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单时间",
                fieldName: "createTime",
                disable: true,
                hidden: more
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "到货日期",
                fieldName: "updateTime",
                disable: true,
                hidden: more
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "采购实体名称",
                fieldName: "bentityName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商名称",
                fieldName: "supplierName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商类型code",
                fieldName: "supplierTypeCode",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "商品类型名称",
                fieldName: "materialTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "采购类型名称",
                fieldName: "purchaseTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "运输方式名称",
                fieldName: "deliveryMethodName",
                hidden: true
            },

        ]
    };



    const onChange = (changedValues: { [key: string]: any }) => {
        let newChangedValues = changedValues;
        if ("supplierCode" in changedValues && changedValues.supplierCode) {
            let { name, typeCode, typeName, contact, phone } = getFindItem(changedValues.supplierCode as string, supplieAllList)
            newChangedValues = { ...changedValues, contact, phone, supplierName: name, supplierTypeCode: typeCode, supplierTypeName: typeName }
        }
        mode === "add" ? updateAddFormVal(newChangedValues) : updatEditFormVal(newChangedValues);
        setTopButsControl(true)
    }


    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        disabled: mode === DetailType.look,
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}