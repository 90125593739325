import React, { useEffect, FC } from "react";
import { Table, Button, Input, Tooltip } from "antd";
import { YHModal } from "@/components/YHModal";
import { useSaleRoDetailModel, IMaterialDetailModel } from "./saleRoDetailModel";
import { ColumnsType } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { RouterParamType } from "@/model/navModel";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { YhBut } from "@/components/YhButton";
import { formatNum, formatIntPoint, bigNumber } from "@/utils/utils";

enum OPT_TYPE {
    ADD = 1,
    REMOVE = 2
}
export const AddGoodsModal: FC<RouterParamType> = ({ id, mode }) => {
    const { initPgCfg, detailAddList, detailDataSource, detailLookList, addModalVisible: visible, workDataSource, workPgCfg, detailAddForm, detailEditForm, addDataSource, searchWorkData, setWorkDataSource, resetWorkloadData, setSearchWorkData, setAddDataSource, transformsToPoDetailList, workloadData, setAddModalVisible, } = useSaleRoDetailModel();
    useEffect(() => {
        if (visible && mode === "add") {
            workloadData(initPgCfg, { customerCode: detailAddForm.customerCode })
            setSearchWorkData({ customerCode: detailAddForm.customerCode })
        } else if (visible && mode === "edit") {
            workloadData(initPgCfg, { customerCode: detailEditForm.customerCode })
            setSearchWorkData({ customerCode: detailEditForm.customerCode })
        }
    }, [detailAddForm.customerCode, detailEditForm.customerCode, visible])

    const typeList = {
        "add": detailAddList,
        "edit": detailDataSource,
        "look": detailLookList,
    }

    const column = (optype: number) => [
        {
            title: '序号',
            width: 50,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '商品编号',
            width: 110,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '商品名称',
            width: 110,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '销售单号',
            width: 110,
            dataIndex: 'saleCode',
        }, {
            title: '销售数量',
            width: 90,
            dataIndex: 'saleNum',
        }, {
            title: '销售单价',
            width: 90,
            dataIndex: 'salePrice',
        }, {
            title: '销售金额',
            width: 90,
            dataIndex: 'saleTotal',

        }, {
            title: '退货数量',
            width: 90,
            dataIndex: 'num',
            editable: true,
        }, {
            title: '退货单价',
            width:90,
            dataIndex: 'price',
            editable: true,
        }, {
            title: '折扣',
            width: 110,
            dataIndex: 'discountRate',
            render: (text: string) => text + '%'
        }, {
            title: '退货金额',
            width: 110,
            dataIndex: 'amount',
        }, {
            title: '单项优惠金额',
            width: 110,
            dataIndex: 'decreaseAmount',
        }, {
            title: '已退优惠金额',
            width: 120,
            dataIndex: 'rdecreaseAmount',
        }, {
            title: '已退数量',
            width: 120,
            dataIndex: 'sreturnNum',
        },{
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        },  {
            title: '操作',
            width: 120,
            fixed: "right",
            align: "center",
            className: "td-wrap",
            render: (text: string, record: IMaterialDetailModel) => optype === OPT_TYPE.ADD ? (
                <YhBut
                    type="add"
                    txt="添加"
                    click={() => add(record)}
                    // disabled={
                    //     !!addDataSource.find(item => item.id === record.id) ||
                    //     record.saleNum === record.sreturnNum ||
                    //     typeList[mode].some(item => item.detailId === record.id)
                    // }
                    disabled={
                        !!addDataSource.find(item => item.id === record.id) ||
                        record.saleNum === record.sreturnNum ||
                        addDataSource.some(item => item.saleCode !== record.saleCode) ||
                        typeList[mode].some(item => item.saleCode !== record.saleCode || item.detailId === record.id)
                    }
                />
            ) : (
                <YhBut
                    type="delete"
                    txt="删除"
                    click={() => remove(record)}
                />
            )
        }
    ];
    const newSelectColumns = (optType: number) =>
        column(optType).map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IMaterialDetailModel, rowIndex: number) => ({
                    colIndex: index,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: optType === OPT_TYPE.ADD ? notHandleSave : handleSave,
                    enterRow: optType === OPT_TYPE.ADD ? enterRow : null,

                }),
            }
        })
    const notHandleSave = (row: IMaterialDetailModel) => {
        let { num, price, sreturnNum, saleNum, discountRate } = row
        saleNum = + formatNum(saleNum)
        sreturnNum = + formatNum(sreturnNum)
        price = + formatNum(price)
        discountRate = + formatNum(discountRate)
        num = +formatIntPoint(num, saleNum - sreturnNum, "退货数量>销售数量")
        let newRow = {
            ...row,
            num,
            price,
            // amount: +formatNum(num * price * discountRate / 100)
            amount:+bigNumber.toFixed(bigNumber.times(bigNumber.times(num,price),bigNumber.dividedBy(discountRate,100)),2)
        }
        const index = workDataSource.findIndex((item) => row.id === item.id);
        const item = workDataSource[index];
        workDataSource.splice(index, 1, { ...item, ...newRow });
        setWorkDataSource([...workDataSource])
    }
    const handleSave = (row: IMaterialDetailModel) => {
        let { num, price, sreturnNum, saleNum, discountRate } = row
        saleNum = + formatNum(saleNum)
        sreturnNum = + formatNum(sreturnNum)
        price = + formatNum(price)
        discountRate = + formatNum(discountRate)

        num = +formatIntPoint(num, saleNum - sreturnNum, "退货数量>销售数量")
        let newRow = {
            ...row,
            num,
            price,
            // amount: +formatNum(num * price * discountRate / 100)
             amount:+bigNumber.toFixed(bigNumber.times(bigNumber.times(num,price),bigNumber.dividedBy(discountRate,100)),2)
        }
        const index = addDataSource.findIndex((item) => row.id === item.id);
        const item = addDataSource[index];
        addDataSource.splice(index, 1, { ...item, ...newRow });
        setAddDataSource([...addDataSource])
    }
    const add = async (record: IMaterialDetailModel) => {
        setAddDataSource(origin => [...origin, record])
    }
    //添加商品  默认数量字段为1
    const allAdd = (record: any) => {
        setAddDataSource(origin => [...origin,  ...record])
    }
    const remove = async (record: IMaterialDetailModel) => {
        const filterAddDataSource = addDataSource.filter(item => item.id !== record.id)
        setAddDataSource(filterAddDataSource)
    }
    const enterRow = async (record: IMaterialDetailModel) => {
        let findArr = addDataSource.find(item => item.materialCode === record.materialCode);
        if (findArr) {
            let filterArr = addDataSource.filter(item => item.materialCode !== findArr?.materialCode);
            setAddDataSource(filterArr)
        } else {
            setAddDataSource(origin => [...origin, record]);
        }
    }
    const onOK = async () => {
        transformsToPoDetailList(mode)
        onCancel()
    }
    const onCancel = () => {
        setAddModalVisible(false);
        setAddDataSource([]);
        mode === "add" ? resetWorkloadData({ customerCode: detailAddForm.customerCode }) : resetWorkloadData({ customerCode: detailEditForm.customerCode })

    };
    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkData.materialCode}
            style={{ width: "130px" }}
            onChange={(e) => { setSearchWorkData({ ...searchWorkData, materialCode: e.target.value }) }}
            onPressEnter={() => workloadData(initPgCfg)}
            placeholder="商品编码"
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkData.materialName}
            style={{ width: "130px" }}
            onChange={(e) => { setSearchWorkData({ ...searchWorkData, materialName: e.target.value }) }}
            onPressEnter={() => workloadData(initPgCfg)}
            placeholder="商品名称"
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkData.saleCodes}
            style={{ width: "130px" }}
            onChange={(e) => { setSearchWorkData({ ...searchWorkData, saleCodes: e.target.value }) }}
            onPressEnter={() => workloadData(initPgCfg)}
            placeholder="销售单号"
        />
        <Button onClick={() => workloadData()} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => mode === "add" ? resetWorkloadData({ customerCode: detailAddForm.customerCode }) : resetWorkloadData({ customerCode: detailEditForm.customerCode })} shape="round">重置</Button>
        <Button type="link" style={{ margin: "0px 12px 0px 15px" }}  onClick={() => allAdd(workDataSource.filter((record)=>!(                        !!addDataSource.find(item => item.id === record.id) ||
                        record.saleNum === record.sreturnNum ||
                        addDataSource.some(item => item.saleCode !== record.saleCode) ||
                        typeList[mode].some(item => item.saleCode !== record.saleCode || item.detailId === record.id))))}>全部添加</Button>
    </div>

    return <YHModal
        visible={visible}
        onOk={onOK}
        onCancel={onCancel}
        title="销售退货单-选择配件"
        width={1000}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选配件列表" rightChildren={rightChildren} style={{ marginBottom: "15px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id as string}
                columns={newSelectColumns(OPT_TYPE.ADD) as ColumnsType<IMaterialDetailModel>}
                dataSource={workDataSource}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                pagination={{
                    ...workPgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        workloadData({ pageNum, pageSize });
                    },
                    showSizeChanger: true
                }}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选配件列表">
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id as string}
                columns={newSelectColumns(OPT_TYPE.REMOVE) as ColumnsType<IMaterialDetailModel>}
                dataSource={[...addDataSource]}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}