import React, { useEffect, FC } from "react";
import { Table, Button, Input, Tooltip } from "antd";
import { YHModal } from "@/components/YHModal";
import { ColumnsType } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { useAppointmentDetailsModel, IMaterialDetailModel } from "./appointmentDetailsModels";
import { RouterParamType } from "@/model/navModel";
import { YhBut } from "@/components/YhButton";
import { bigNumber, formatIntPoint, formatNum } from "@/utils/utils";
import { components } from "@/components/YHEditTable/editTable";
enum OPT_TYPE {
    ADD = 1,
    REMOVE = 2
}
export const MaterialModal: FC<RouterParamType> = ({ id, mode }) => {
    const {
        materialModel: visible,
        setMaterialModel,
        searchWorkMaterialData,
        workMaterialDataSource,
        addDataMaterialSource,
        initPgCfg,
        workMaterialPgCfg,
        detailMaterialAddList,
        detailMaterialEditList,
        workMaterialloadData,
        resetWorkMaterialloadData,
        setSearchWorkMaterialData,
        setAddDataMaterialSource,
        setWorkMaterialDataSource,
        transformsToPoMaterialDetailList
    } = useAppointmentDetailsModel();
    const materialList= mode === 'add' ? detailMaterialAddList : detailMaterialEditList
    const onOk = () => {
        transformsToPoMaterialDetailList(mode)
        onCancel()
    }
    const onCancel = () => {
        setMaterialModel(false);
        setAddDataMaterialSource([]);
        setSearchWorkMaterialData({})
    };
    useEffect(() => {
        if (visible && mode === "add") {
            workMaterialloadData(initPgCfg, {}, "add")
        } else if (visible && mode === "edit") {
            workMaterialloadData(initPgCfg, {}, "edit")
        }
    }, [visible])

    const column = (optype: number) => [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '配件编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '配件名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        }, {
            title: '可用库存',
            width: 120,
            dataIndex: 'availableStock',
        }, {
            title: '采购单号',
            width: 160,
            dataIndex: 'purchaseCode',
        }, {
            title: '使用数量',
            width: 120,
            dataIndex: 'num',
            editable: true,

        }, {
            title: '价格',
            width: 120,
            dataIndex: 'lateSalePrice',
            editable: true,

        }, {
            title: '总计',
            width: 120,
            dataIndex: 'amount',
        }, {
            title: '库房',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '积压标记',
            width: 120,
            dataIndex: 'overstock',
        }, {
            title: '操作',
            width: 120,
            fixed: "right",
            render: (text: any, record: any) => optype === OPT_TYPE.ADD ? (
                <YhBut
                    type="add"
                    txt="添加"
                    click={() => add(record)}
                    disabled={
                        !!addDataMaterialSource.find(item => item.id === record.id) ||
                        !!materialList.find(item => item.detailsId === record.id) ||
                        record.availableStock <= 0
                    }
                />
            ) : (
                    <YhBut
                        type="delete"
                        txt="删除"
                        click={() => remove(record)}
                    />
                )
        }
    ];
    const newSelectColumns = (optType: number) =>
        column(optType).map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IMaterialDetailModel, rowIndex: number) => ({
                    colIndex: index,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: optType === OPT_TYPE.ADD ? notHandleSave : handleSave,
                    enterRow: optType === OPT_TYPE.ADD ? enterRow : null,
                }),
            }
        })
    const add = async (record: IMaterialDetailModel) => {
        setAddDataMaterialSource(origin => [...origin, record])
    }
    const remove = async (record: IMaterialDetailModel) => {
        const filterAddDataSource = addDataMaterialSource.filter(item => item.id != record.id)
        setAddDataMaterialSource(filterAddDataSource)
    }
    const enterRow = async (record: IMaterialDetailModel) => {
        let findArr = addDataMaterialSource.find(item => item.materialCode === record.materialCode);
        if (findArr) {
            let filterArr = addDataMaterialSource.filter(item => item.materialCode !== findArr?.materialCode);
            setAddDataMaterialSource(filterArr)
        } else {
            setAddDataMaterialSource(origin => [...origin, record]);
        }
    }
    const notHandleSave = (row: IMaterialDetailModel) => {
        let { num, lateSalePrice, inventoryNum,availableStock } = row;
        num = +formatIntPoint(num);
        lateSalePrice = +formatIntPoint(lateSalePrice);
        lateSalePrice = +formatNum(lateSalePrice);
        let newRow = {
            ...row,
            num,
            //amount: +formatNum(num * lateSalePrice)
            amount:+bigNumber.toFixed(bigNumber.times(num,lateSalePrice),2),
        }
        const index = workMaterialDataSource.findIndex((item) => row.id === item.id);
        const item = workMaterialDataSource[index];
        workMaterialDataSource.splice(index, 1, { ...item, ...newRow });
        setWorkMaterialDataSource([...workMaterialDataSource])
    }
    const handleSave = (row: IMaterialDetailModel) => {
        let { num, lateSalePrice, inventoryNum,availableStock } = row;
        // num = +formatIntPoint(num, availableStock, "选择数量>=可用库存数量");
        num = +formatIntPoint(num);
        lateSalePrice = +formatIntPoint(lateSalePrice);
        lateSalePrice = +formatNum(lateSalePrice);
        let newRow = {
            ...row,
            num,
            //amount: +formatNum(num * lateSalePrice)
            amount:+bigNumber.toFixed(bigNumber.times(num,lateSalePrice),2),
        }
        const index = addDataMaterialSource.findIndex((item) => row.id === item.id);
        const item = addDataMaterialSource[index];
        addDataMaterialSource.splice(index, 1, { ...item, ...newRow });
        setAddDataMaterialSource([...addDataMaterialSource])
    }
    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkMaterialData.materialCode}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkMaterialData({ ...searchWorkMaterialData, materialCode: e.target.value }) }}
            // onPressEnter={() => workMaterialloadData(initPgCfg)}
            onPressEnter={() => workMaterialloadData(initPgCfg, {...searchWorkMaterialData}, mode)}
            placeholder="商品编码"
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkMaterialData.materialName}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkMaterialData({ ...searchWorkMaterialData, materialName: e.target.value }) }}
            // onPressEnter={() => workMaterialloadData(initPgCfg)}
            onPressEnter={() => workMaterialloadData(initPgCfg, {...searchWorkMaterialData}, mode)}
            placeholder="商品名称"
        />
        <Button onClick={() => workMaterialloadData(initPgCfg, {...searchWorkMaterialData}, mode)} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => resetWorkMaterialloadData()} shape="round">重置</Button>
    </div>

    return <YHModal
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        title="预约管理-选择配件"
        width={1000}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选配件列表" rightChildren={rightChildren} style={{ marginBottom: "5px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id as string}
                columns={newSelectColumns(OPT_TYPE.ADD) as ColumnsType<IMaterialDetailModel>}
                dataSource={workMaterialDataSource}
                pagination={{
                    ...workMaterialPgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        workMaterialloadData({ pageNum, pageSize }).then();
                    },
                    showSizeChanger: true
                }}
                components={components}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选配件列表">
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id as string}
                columns={newSelectColumns(OPT_TYPE.REMOVE) as ColumnsType<IMaterialDetailModel>}
                dataSource={addDataMaterialSource}
                pagination={false}
                components={components}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}